import React from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { FullBackground, Icon, Layout, PageContainer, SEO } from "components";
import { Link as GatsbyLink } from "gatsby";
import { device, routes } from "utils";

const GetStartedOverviewPage = ({ location }) => {
  const title = "Get Started with ECL | Overview";
  const description =
    "Find out how to get your Enterprise, Startup, Academia or Non-profits account started";
  return (
    <FullBackground>
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <PageContainer style={{ borderTop: "1px solid #53575b" }}>
          <S.Heading>
            <S.MainTitle>A Plan for Every Scientist</S.MainTitle>
            <S.SubHeader>
              Customers of all sizes, from small startups to Fortune 500, rely
              on ECL.
            </S.SubHeader>
            <S.SubHeader>
              We work with each customer to find a price, plan, and solution
              that fits your business.
            </S.SubHeader>
          </S.Heading>
          <S.Row>
            <S.Col xs={12} md={3}>
              <S.GatsbyIconLink
                to={routes("contactUs")}
                state={{ accountType: "Enterprise" }}
                className="startup-icon"
              >
                <S.Graphic name="EnterpriseIconBackground" />
                <S.Graphic
                  name="EnterpriseIconForeground"
                  className="foreground"
                />
              </S.GatsbyIconLink>
              <S.CapacityTitle>Enterprise</S.CapacityTitle>
              <S.CapacityDesc>
                Enterprise-scale R&amp;D and CMC solution with GxP support.
              </S.CapacityDesc>
              <S.GatsbyLink
                to={routes("contactUs")}
                state={{ accountType: "Enterprise" }}
              >
                <S.ActionButton>Contact Sales</S.ActionButton>
              </S.GatsbyLink>
              <S.Bullets>
                <li>ECL Command Center&reg; access and training</li>
                <li>Personalized application support</li>
                <li>
                  Automatic data acquisition, storage, association, and audit
                  trail
                </li>
                <li>Auto-QC and maintenance of all equipment</li>
                <li>Remote experiment setup, execution, and tracking</li>
                <li>Data analysis and plotting suite</li>
                <li>GxP compliance assistance</li>
                <li>IT integration support</li>
                <li>Infosec auditing</li>
                <li>AD federation and multi-factor authentication</li>
                <li>Unlimited data access accounts</li>
              </S.Bullets>
            </S.Col>
            <S.Col xs={12} md={3}>
              <S.GatsbyIconLink
                to={routes("contactUs")}
                state={{ accountType: "Startup" }}
                className="startup-icon"
              >
                <S.Graphic name="StartupIconBackground" />
                <S.Graphic
                  name="StartupIconForeground"
                  className="foreground"
                />
              </S.GatsbyIconLink>
              <S.CapacityTitle>Start Up Companies</S.CapacityTitle>
              <S.CapacityDesc>
                A complete research solution for early to mid-stage companies,
                with a seamless upgrade path to Enterprise as you scale.
              </S.CapacityDesc>
              <S.GatsbyLink
                to={routes("contactUs")}
                state={{ accountType: "Startup" }}
              >
                <S.ActionButton>Contact Sales</S.ActionButton>
              </S.GatsbyLink>
              <S.Bullets>
                <li>ECL Command Center&reg; access and training</li>
                <li>Biweekly application support</li>
                <li>
                  Automatic data acquisition, storage, association, and audit
                </li>
                <li>Auto-QC and maintenance of all equipment</li>
                <li>Remote experiment setup, execution, and tracking</li>
                <li>Data analysis and plotting suite</li>
              </S.Bullets>
            </S.Col>
            <S.Col xs={12} md={3}>
              <S.GatsbyIconLink
                to={routes("contactUs")}
                state={{ accountType: "Academia" }}
                className="academia-icon"
              >
                <S.Graphic name="AcademiaIconBackground" />
                <S.Graphic
                  name="AcademiaIconForeground"
                  className="foreground"
                />
              </S.GatsbyIconLink>
              <S.CapacityTitle>
                Academia &amp; Non-Profit Investigators
              </S.CapacityTitle>
              <S.CapacityDesc>
                The full power of ECL, available for academia and other
                non-profit institutions.
              </S.CapacityDesc>
              <S.GatsbyLink
                to={routes("contactUs")}
                state={{ accountType: "Academia" }}
              >
                <S.ActionButton>Contact Sales</S.ActionButton>
              </S.GatsbyLink>
              <S.Bullets>
                <li>ECL Command Center&reg; access and training</li>
                <li>Biweekly application support</li>
                <li>
                  Automatic data acquisition, storage, association, and audit
                </li>
                <li>Auto-QC and maintenance of all equipment</li>
                <li>Remote experiment setup, execution, and tracking</li>
                <li>Data analysis and plotting suite</li>
              </S.Bullets>
            </S.Col>
            <S.Col xs={12} md={3}>
              <S.GatsbyIconLink
                to={routes("contactUs")}
                state={{ accountType: "Class" }}
                className="academic-class-icon"
              >
                <S.Graphic name="ClassIconBackground" />
                <S.Graphic name="ClassIconForeground" className="foreground" />
              </S.GatsbyIconLink>
              <S.CapacityTitle>
                Academic Classes &amp; Administrators
              </S.CapacityTitle>
              <S.CapacityDesc>
                An integrated solution enabling students to develop the skills
                required to work in remote cloud laboratories.
              </S.CapacityDesc>
              <S.GatsbyLink
                to={routes("contactUs")}
                state={{ accountType: "Class" }}
              >
                <S.ActionButton>Contact Sales</S.ActionButton>
              </S.GatsbyLink>
              <S.Bullets>
                <li>ECL Command Center&reg; access and training</li>
                <li>Instructor-run protocols and training</li>
                <li>ECL instructor assistance for course prototyping</li>
                <li>Weekly ECL instructor-led office hours</li>
                <li>Pre-crafted problem sets</li>
                <li>Pre-crafted exams</li>
                <li>Automatic data acquisition, storage, and audit</li>
                <li>Auto-QC and maintenance of all equipment</li>
                <li>Remote experiment setup, execution, and tracking</li>
                <li>Data analysis and plotting suite</li>
              </S.Bullets>
            </S.Col>
          </S.Row>
        </PageContainer>
        <S.ContactBanner>
          <p>Want to see a live technology demonstration?</p>
          <a
            href={routes("contactUs")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.ActionButton>GET STARTED</S.ActionButton>
          </a>
        </S.ContactBanner>
      </Layout>
    </FullBackground>
  );
};

export default GetStartedOverviewPage;

const S = {};

// TODO: The header parts matches the other two main pages.
// Move it to somewhere common
// Heading + MainTitle + SubHeader

S.Heading = styled.div`
  // letter-spacing: 0.07rem;
  margin-bottom: 0;
  margin-top: 10.3rem;
  text-align: center;

  @media ${device.md} {
    margin-bottom: 5.5rem;
    margin-top: 6.5rem;
  }
`;

S.MainTitle = styled.h1`
  color: ${({ theme }) => theme.white};
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.6rem;
  margin-bottom: 1rem;
`;

S.SubHeader = styled.p`
  color: #b1bac2;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;

  // @media ${device.md} {
  //   margin-top: 1.5rem;
  // }
`;

S.Row = styled(Row)`
  margin: 0;
  // justify-content: center;
`;

S.Col = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  // TODO: Tune
  padding: 0 1.5rem;
`;

S.GatsbyIconLink = styled(GatsbyLink)`
  position: relative;
`;

S.Graphic = styled(Icon)`
  cursor: pointer;
  height: auto;
  width: 19rem;
  margin-bottom: 4rem;
  transition: all 0.5s ease;
  &.foreground {
    position: absolute;
    top: 0;
    left: 0;
    @media ${device.sm} {
      &:hover {
        transform: scale(1.15);
      }
    }
  }
`;

// It's used in virtualtour and getStartedHeader as well.
// Same in virtualtour and a bit different in getStartedHeader.
// Can centralize into a component.
S.ContactBanner = styled.div`
  background-color: #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;
  margin-bottom: 5rem;
  text-align: center;
  max-width: 96rem;
  margin: auto;
  p {
    margin: 0 0 1rem 0;
    font-size: 1.7rem;
    font-weight: 300;
    color: ${({ theme }) => theme.white};
  }

  @media ${device.sm} {
    flex-direction: row;
    p {
      margin: 0;
    }
  }
`;

S.CapacityTitle = styled.h3`
  color: ${({ theme }) => theme.white};
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.4rem;
  margin-bottom: 0;
  text-align: center;
  @media all and ${device.md} {
    // HACKY way to make sure the START ESTIMATE buttons on the 4 columns
    // stay aligned when shrinking down to mobile view
    min-height: 6rem;
  }
`;

S.CapacityDesc = styled.p`
  min-height: 6.8rem;
  margin-bottom: 1rem;
  line-height: 1.7rem;
  font-size: 1.7rem;
  font-weight: 300;
  text-align: center;
  // TODO: Make this a theme
  color: #b1bac2;
  @media all and ${device.md} {
    // HACKY way to make sure the START ESTIMATE buttons on the 4 columns
    // stay aligned when shrinking down to mobile view
    min-height: 10rem;
  }
`;

S.GatsbyLink = styled(GatsbyLink)`
  @media all and ${device.md} {
    margin: 0 1.25rem;
    align-self: stretch;
    text-align: center;
    max-width: 25rem;
  }
`;

S.ActionButton = styled.div`
  cursor: pointer;
  padding: 0.5rem 1.4rem;
  border: 1px solid #b1bac2;
  border-radius: 0;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.white};
  &:hover {
    background-color: #000000;
    border-color: #ffffff;
  }
`;

// TODO: Update size/spacing
S.Bullets = styled.ul`
  margin: auto;
  margin-top: 2.8rem;
  margin-bottom: 5rem;
  color: #b1bac2;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.8rem;
  letter-spacing: 0;
  padding-left: 2rem;
`;
